<template>
  <div class="d-flex items-center justify-between navbar" :class="{'navbar__home': $route.name === 'Home'}">
    <div class="d-flex items-center">
      <img v-if="lang === 'hk'" src="@/assets/img/en-icon.svg" @click="onLangBtnClick">
      <img v-else src="@/assets/img/hk-icon.svg" @click="onLangBtnClick">
      <div v-if="$route.name !== 'Home'" class="navbar__content-btn-group" @click="homePageTrack({ lan: lang })">
        <router-link to="/">
          <img src="@/assets/img/home.svg" alt="回到首頁">
        </router-link>
        <img src="@/assets/img/back.svg" alt="上一頁" class="navbar__back-btn" @click="$router.go(-1)">
      </div>
      <BreadCrumb v-if="$route.name !== 'Home'" />
    </div>
    <div
      class="d-flex items-center justify-between navbar__cart"
      @click="$route.name === 'Cart' || $router.push({ name: 'Cart' })"
    >
      <span v-if="lang === 'hk'" class="navbar__cart-text">{{ cart_hk }}</span>
      <span v-else class="navbar__cart-text navbar__cart-text--en">{{ cart_en }}</span>
      <div class="d-flex justify-center navbar__cart-heart">
        <span>{{ cartList.length }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import BreadCrumb from '@/components/common/BreadCrumb'
export default {
  name: 'Navbar',
  components: {
    BreadCrumb
  },
  computed: {
    ...mapState('lang', ['lang']),
    ...mapState('navbar', ['cart_hk', 'cart_en']),
    ...mapState('cart', ['cartList'])
  },
  methods: {
    ...mapMutations('lang', ['TOGGLE_LANG']),
    ...mapActions('gtagEvents', ['homePageTrack']),
    onLangBtnClick () {
      const newLang = this.lang === 'hk' ? 'en' : 'hk'
      this.TOGGLE_LANG(newLang)
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  padding: 0 2.6042vw;
  height: 20.37vh;

  &__content-btn-group {
    margin-left: 0.78125vw;
    margin-right: 1.5625vw;
  }

  &__home {
    height: auto;
    padding: 8.52vh 4.16667vw;
    padding-bottom: 5.648vh;
  }

  &__back-btn {
    margin-left: 0.78125vw;
  }

  &__cart-text {
    font-size: 1.771vw;
    margin-right: 1.09375vw;
    color: $black;
    font-family: 'NotoIKEA-bold';

    &--en {
      font-size: 1.3021vw;
      letter-spacing: -0.0390625vw;
      margin-right: 0.599vw;
    }
  }

  &__cart-heart {
    width: 2.24vw;
    height: 4.1667vh;
    padding-left: 0.521vw;
    padding-top: 0.463vh;
    @include bg-cover;
    background-image: url('~@/assets/img/cart-icon.svg');
    font-size: 0.677vw;
    color: $white;
  }
}
</style>
