// 此檔案用來管理首頁的 state
const state = {
  activity_hk: '精選推介',
  activity_en: 'Highlights',
  inspiration_hk: '探索空間靈感',
  inspiration_en: 'Explore Inspirations',
  tip_hk: '觸碰探索佈置靈感',
  tip_en: 'Touch to',
  tip_en2: 'explore inspirations'
}

const mutations = {}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
