<template>
  <div class="position-relative d-flex click-section">
    <div class="flex-column items-center click-section__block" @click="onHighlightLinkClick">
      <img src="@/assets/img/index/news-icon.svg" alt="精選推介">
      <p v-if="lang === 'hk'" class="font-bold click-section__text">{{ activity_hk }}</p>
      <p v-else class="font-bold click-section__text">{{ activity_en }}</p>
    </div>
    <div class="flex-column items-center click-section__block" @click="onInspirationWallLinkClick">
      <img src="@/assets/img/index/inspiration-icon.svg" alt="探索空間靈感">
      <p v-if="lang === 'hk'" class="font-bold click-section__text-inspiration">{{ inspiration_hk }}</p>
      <p v-else class="font-bold click-section__text-inspiration click-section__text-inspiration--en">{{ inspiration_en }}</p>
    </div>
    <div class="flex-column items-center click-section__touch-tip">
      <div class="click-section__touch-tip-line"></div>
      <div class="flex-column justify-center click-section__touch-tip-view">
        <div class="d-flex justify-center items-center">
          <img src="@/assets/img/index/arrow-left.svg" class="click-section__arrow">
          <div class="click-section__hand"></div>
          <img src="@/assets/img/index/arrow-right.svg" class="click-section__arrow">
        </div>
        <p v-if="lang === 'hk'" class="click-section__touch-tip-text">{{ tip_hk }}</p>
        <div v-else>
          <p class="font-bold click-section__touch-tip-text click-section__touch-tip-text--en">{{ tip_en }}</p>
          <p class="font-bold click-section__touch-tip-text click-section__touch-tip-text--en">{{ tip_en2 }}</p>
        </div>
      </div>
      <div class="click-section__touch-tip-line"></div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import lottie from 'lottie-web'
import hand from '@/assets/lottie/hand_gray.json'

export default {
  name: 'ClickSection',
  data () {
    return {
      lottie: {}
    }
  },
  computed: {
    ...mapState('lang', ['lang']),
    ...mapState('home', ['activity_hk', 'activity_en', 'inspiration_hk', 'inspiration_en', 'tip_hk', 'tip_en', 'tip_en2'])
  },
  mounted () {
    this.$nextTick(() => {
      lottie.loadAnimation({
        container: document.querySelector('.click-section__hand'),
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: hand
      })
    })
  },
  methods: {
    ...mapActions('gtagEvents', ['homePageOptionTrack']),
    onHighlightLinkClick () {
      this.homePageOptionTrack({ type: 'HL', lan: this.lang })
      this.$router.push({ name: 'Highlight' })
    },
    onInspirationWallLinkClick () {
      this.homePageOptionTrack({ type: 'IP', lan: this.lang })
      this.$router.push({ name: 'Inspiration' })
    }
  }
}
</script>

<style lang="scss" scoped>
.click-section {
  flex-grow: 1;

  &__block {
    flex: 0 0 50%;
    padding-top: calc(22.41vh - 8.52vh);
  }

  &__text {
    font-size: 2.7604vw;
    margin-top: 7.074vh;
    color: #000;
  }

  &__text-inspiration {
    font-size: 2.7604vw;
    margin-top: 5.5556vh;
    color: #000;

    &--en {
      max-width: 16.823vw;
      text-align: center;
      line-height: normal;
    }
  }

  &__touch-tip {
    position: absolute;
    height: 100%;
    top: 0;
    width: 16.6667vw;
    left: calc(50vw - 8.33333vw);
  }

  &__touch-tip-line {
    width: 0.15625vw;
    height: 20.37vh;
    background-color: $black;
  }

  &__touch-tip-view {
    min-height: calc(9.7222vh * 2 + 1.111vh + 2.87vh + 8.295vh);
  }

  &__arrow {
    width: 0.8333vw;
  }

  &__hand {
    width: 7.8125vw;
    height: 7.8125vw;
  }

  &__touch-tip-text {
    font-size: 1.6667vw;
    line-height: 0.97;
    color: $gray;
    text-align: center;
    margin-top: 0.741vh;

    &--en {
      max-width: 16.6667vw;
      line-height: 1.31;
      align-self: center;

      &:last-child {
        margin-top: 0;
      }
    }
  }
}
</style>
