<template>
  <div id="app" class="flex-column">
    <loading :active.sync="isLoading" />
    <ScreenSaverTimer v-if="$route.name !== 'TopCarousel' && $route.name !== 'BottomCarousel'" />
    <ScreenSaver v-if="$route.name !== 'TopCarousel' && $route.name !== 'BottomCarousel'" />
    <Navbar v-if="$route.name !== 'TopCarousel' && $route.name !== 'BottomCarousel'" />
    <router-view/>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import Navbar from '@/components/common/Navbar'
import ScreenSaver from '@/components/common/ScreenSaver'
import ScreenSaverTimer from '@/components/common/ScreenSaverTimer'

export default {
  components: {
    Navbar,
    ScreenSaver,
    ScreenSaverTimer
  },
  computed: {
    ...mapState('global', ['isLoading'])
  },
  created () {
    this.fetchScreenSaver()
  },
  mounted () {
    this.RESET_TIMER()
    const body = document.querySelector('body')
    body.addEventListener('click', () => {
      this.RESET_TIMER()
    })
  },
  methods: {
    ...mapMutations('screensaver', ['RESET_TIMER']),
    ...mapActions('api', ['fetchScreenSaver'])
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/all.scss';
#app {
  height: 100vh;
  overflow: hidden;
}
</style>
