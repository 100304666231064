const state = {
  phase: {},
  home: [],
  inspiration: [],
  highlight: [],
  category: [],
  highlightProduct: []
}

const mutations = {
  UPDATE_PHASE (state, payload) {
    state.phase = payload
  },
  UPDATE_HOME (state, payload) {
    state.home = payload
  },
  UPDATE_INSPIRATION (state, payload) {
    state.inspiration = payload
  },
  UPDATE_HIGHLIGHT (state, payload) {
    state.highlight = payload
  },
  UPDATE_CATEGORY (state, payload) {
    state.category = payload
  },
  UPDATE_HIGHLIGHT_PRODUCT (state, payload) {
    state.highlightProduct = payload
  },
  UPDATE_INDEX (state, idx) {
    state.phase.current_id = idx
  }
}

const actions = {
  checkPhase ({ commit, dispatch }, payload) {
    if (state.phase.phase === payload.phase && state.phase.current_id === payload.current_id) {
      setTimeout(() => {
        dispatch('api/fetchBigScreenStatus', null, { root: true })
      }, 1000)
    } else if (state.phase.phase === payload.phase && payload.phase === 'screensaver') {
      commit('UPDATE_INDEX', payload.current_id)
      setTimeout(() => {
        dispatch('api/fetchBigScreenStatus', null, { root: true })
      }, 1000)
    } else {
      commit('UPDATE_PHASE', payload)
      setTimeout(() => {
        dispatch('api/fetchBigScreenStatus', null, { root: true })
      }, 1000)
    }
  }
}

const getters = {
  activeIdx: state => {
    return state.phase.phase === 'screensaver' ? state.phase.current_id : 'none'
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
