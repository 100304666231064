<template>
  <div class="flex-column home">
    <click-section />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ClickSection from '@/components/home/ClickSection'

export default {
  name: 'Home',
  components: {
    ClickSection
  },
  computed: {
    ...mapState('lang', ['lang'])
  },
  created () {
    this.fetchBigScreenPhase({ current: '00', current_id: '', lang: this.lang })
  },
  methods: {
    ...mapActions('api', ['fetchBigScreenPhase'])
  }
}
</script>

<style lang="scss" scoped>
.home {
  flex-grow: 1;
}
</style>
