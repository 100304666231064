const state = {
  breadcrumb: {
    inspiration: '探索空間靈感',
    inspiration_en: 'Explore Inspirations',
    category: [],
    category_en: [],
    product: [],
    product_en: [],
    highlight: '精選推介',
    highlight_en: 'Highlights',
    highlight_product: '',
    highlight_product_en: ''
  }
}

const mutations = {
  UPDATE_HIGHLIGHT_PRODUCT (state, text) {
    state.breadcrumb.highlight_product = text
  },
  UPDATE_HIGHLIGHT_PRODUCT_EN (state, text) {
    state.breadcrumb.highlight_product_en = text
  },
  UPDATE_CATEGORY (state, list) {
    state.breadcrumb.category = list
  },
  UPDATE_CATEGORY_EN (state, list) {
    state.breadcrumb.category_en = list
  },
  UPDATE_PRODUCT (state, list) {
    state.breadcrumb.product = list
  },
  UPDATE_PRODUCT_EN (state, list) {
    state.breadcrumb.product_en = list
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
