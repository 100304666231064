const state = {
  space: [],
  space_en: [],
  category: [],
  category_en: []
}

const mutations = {
  UPDATE_LIST (state, payload) {
    state.space = payload
  },
  UPDATE_EN_LIST (state, payload) {
    state.space_en = payload
  },
  UPDATE_CATEGORY (state, payload) {
    state.category = payload
  },
  UPDATE_EN_CATEGORY (state, payload) {
    state.category_en = payload
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
