const state = {
  highlight: [],
  highlight_en: []
}

const mutations = {
  UPDATE_HIGHLIGHT (state, payload) {
    state.highlight = payload
  },
  UPDATE_EN_HIGHLIGHT (state, payload) {
    state.highlight_en = payload
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
