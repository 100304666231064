// 用來管理購物車
const state = {
  cartList: [],
  qrcode: ''
}

const mutations = {
  ADD_PRODUCT (state, product) {
    state.cartList.push(product)
  },
  REMOVE_PRODUCT (state, id) {
    const list = [...state.cartList]
    state.cartList = list.filter(item => item.id !== id)
  },
  REMOVE_PRODUCT_ALL (state) {
    state.cartList = []
  },
  UPDATE_COUNT (state, payload) {
    const t = state.cartList.find(item => item.id === payload.id)
    const i = state.cartList.findIndex(item => item.id === payload.id)

    if (payload.type === 'plus') t.count += 1
    else t.count -= 1

    state.cartList.splice(i, 1, t)
  },
  UPDATE_QRCODE (state, payload) {
    state.qrcode = payload
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
