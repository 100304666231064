const state = {}

const mutations = {}

const actions = {
  totalClickTrack (context, payload) {
    window.gtag('event', `IW ${payload.lang} ${payload.type} total clk`, {
      event_category: 'Click',
      event_label: 'IW-total-click'
    })
  },
  homePageTrack (context, payload) {
    const lang = payload.lan === 'en' ? 'EN' : 'TC'
    window.gtag('event', `IW ${lang} General homepage clk`, {
      event_category: 'Click',
      event_label: 'IW-homepage-click'
    })
  },
  homePageOptionTrack (context, payload) {
    const lang = payload.lan === 'en' ? 'EN' : 'TC'
    window.gtag('event', `IW ${lang} General ${payload.type} clk`, {
      event_category: 'Click',
      event_label: 'IW-home-option-click'
    })
    context.dispatch('totalClickTrack', { lang, type: payload.type })
  },
  highlightOptionTrack (context, payload) {
    const lang = payload.lan === 'en' ? 'EN' : 'TC'
    window.gtag('event', `IW ${lang} HL ${payload.gaCode} clk`, {
      event_category: 'Click',
      event_label: 'IW-highlights-option-click'
    })
    context.dispatch('totalClickTrack', { lang, type: 'HL' })
  },
  addFavoriteListTrack (context, payload) {
    const lang = payload.lan === 'en' ? 'EN' : 'TC'
    window.gtag('event', `IW ${lang} ${payload.type} ${payload.code} fav`, {
      event_category: 'Click',
      event_label: 'IW-add-favorite-click'
    })
    context.dispatch('totalClickTrack', { lang, type: payload.type })
  },
  getQrcodeTrack (context, payload) {
    const lang = payload.lan === 'en' ? 'EN' : 'TC'
    window.gtag('event', `IW ${lang} General total qrclk`, {
      event_category: 'Click',
      event_label: 'IW-get-QRcode-click'
    })
  },
  roomsClickTrack (context, payload) {
    const lang = payload.lan === 'en' ? 'EN' : 'TC'
    window.gtag('event', `IW ${lang} IP ${payload.room} clk`, {
      event_category: 'Click',
      event_label: 'IW-showroom-click'
    })
    context.dispatch('totalClickTrack', { lang, type: 'IP' })
  },
  inspirationClickTrack (context, payload) {
    const lang = payload.lan === 'en' ? 'EN' : 'TC'
    window.gtag('event', `IW ${lang} IP ${payload.name} ${payload.gaCode} clk`, {
      event_category: 'Click',
      event_label: 'IW-inspiration-click'
    })
    context.dispatch('totalClickTrack', { lang, type: 'IP' })
  },
  itemInOwnListTrack (context, payload) {
    const lang = payload.lan === 'en' ? 'EN' : 'TC'
    window.gtag('event', `IW ${lang} General ${payload.code} ownlist ${payload.count} of items`, {
      event_category: 'render',
      event_label: 'IW-item-in-list'
    })
  },
  screenSaverClickTrack () {
    window.gtag('event', 'IW looping user', {
      event_category: 'Click',
      event_label: 'IW-screenSaver-click'
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
