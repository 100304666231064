import axios from 'axios'

const state = {
  screenSaver: []
}

const mutations = {
  SET_SCREEN_SAVER (state, data) {
    state.screenSaver = data
  }
}

const actions = {
  async fetchScreenSaver ({ commit }) {
    commit('global/TOGGLE_LOADING', true, { root: true })
    await axios.get(`${process.env.VUE_APP_BASE_URL}/api/web/screensaver`).then(({ data }) => {
      if (data.data.screen_saver.length) commit('SET_SCREEN_SAVER', data.data.screen_saver)
      commit('global/TOGGLE_LOADING', false, { root: true })
    })
  },
  async fetchShowroomType ({ commit }) {
    commit('global/TOGGLE_LOADING', true, { root: true })
    await Promise.all([
      axios.get(`${process.env.VUE_APP_BASE_URL}/api/web/showroomType`).then(({ data }) => {
        commit('inspiration/UPDATE_LIST', data.data.showroom_type, { root: true })
        commit('breadcrumb/UPDATE_CATEGORY', data.data.showroom_type, { root: true })
      }),
      axios.get(`${process.env.VUE_APP_BASE_URL}/api/web/showroomType?lang=en`).then(({ data }) => {
        commit('inspiration/UPDATE_EN_LIST', data.data.showroom_type, { root: true })
        commit('breadcrumb/UPDATE_CATEGORY_EN', data.data.showroom_type, { root: true })
      })
    ])
    commit('global/TOGGLE_LOADING', false, { root: true })
  },
  async fetchShowroomList ({ commit }, id) {
    commit('global/TOGGLE_LOADING', true, { root: true })
    await Promise.all([
      axios.get(`${process.env.VUE_APP_BASE_URL}/api/web/showroom?showroom_type_id=${id}`).then(({ data }) => {
        commit('inspiration/UPDATE_CATEGORY', data.data.showroom, { root: true })
        commit('breadcrumb/UPDATE_PRODUCT', data.data.showroom, { root: true })
      }),
      axios.get(`${process.env.VUE_APP_BASE_URL}/api/web/showroom?showroom_type_id=${id}&lang=en`).then(({ data }) => {
        commit('inspiration/UPDATE_EN_CATEGORY', data.data.showroom, { root: true })
        commit('breadcrumb/UPDATE_PRODUCT_EN', data.data.showroom, { root: true })
      })
    ])
    commit('global/TOGGLE_LOADING', false, { root: true })
  },
  async fetchProduct ({ commit }, id) {
    commit('global/TOGGLE_LOADING', true, { root: true })
    await Promise.all([
      axios.get(`${process.env.VUE_APP_BASE_URL}/api/web/showroom/detail?showroom_id=${id}`).then(({ data }) => {
        commit('product/UPDATE_DATA', data.data, { root: true })
      }),
      axios.get(`${process.env.VUE_APP_BASE_URL}/api/web/showroom/detail?showroom_id=${id}&lang=en`).then(({ data }) => {
        commit('product/UPDATE_EN_DATA', data.data, { root: true })
      })
    ])
    commit('global/TOGGLE_LOADING', false, { root: true })
  },
  async fetchHighlight ({ commit }) {
    commit('global/TOGGLE_LOADING', true, { root: true })
    await Promise.all([
      axios.get(`${process.env.VUE_APP_BASE_URL}/api/web/highlight`).then(({ data }) => {
        commit('highlight/UPDATE_HIGHLIGHT', data.data.highlight, { root: true })
      }),
      axios.get(`${process.env.VUE_APP_BASE_URL}/api/web/highlight?lang=en`).then(({ data }) => {
        commit('highlight/UPDATE_EN_HIGHLIGHT', data.data.highlight, { root: true })
      })
    ])
    commit('global/TOGGLE_LOADING', false, { root: true })
  },
  async fetchProductQrcode ({ commit }, payload) {
    commit('global/TOGGLE_LOADING', true, { root: true })
    const url = payload.lang === 'hk' ? `${process.env.VUE_APP_BASE_URL}/api/web/product/qrcode` : `${process.env.VUE_APP_BASE_URL}/api/web/product/qrcode?lang=en`
    await axios.post(url, payload.list).then(({ data }) => {
      commit('cart/UPDATE_QRCODE', data.data.qrcode, { root: true })
    })
    commit('global/TOGGLE_LOADING', false, { root: true })
  },
  async fetchBigScreen ({ commit }) {
    commit('global/TOGGLE_LOADING', true, { root: true })
    await axios.get(`${process.env.VUE_APP_BASE_URL}/api/web/bigScreen`).then(({ data }) => {
      commit('bigScreen/UPDATE_HOME', data.data.big_screen['00'], { root: true })
      commit('bigScreen/UPDATE_INSPIRATION', data.data.big_screen['01'], { root: true })
      commit('bigScreen/UPDATE_HIGHLIGHT', data.data.big_screen['02'], { root: true })
      commit('bigScreen/UPDATE_CATEGORY', data.data.big_screen.showroom_type, { root: true })
      commit('bigScreen/UPDATE_HIGHLIGHT_PRODUCT', data.data.big_screen.highlight, { root: true })
    })
    commit('global/TOGGLE_LOADING', false, { root: true })
  },
  async fetchBigScreenPhase ({ commit }, payload) {
    await axios.post(`${process.env.VUE_APP_BASE_URL}/api/web/phase`, payload)
  },
  async fetchBigScreenStatus ({ dispatch }) {
    await axios.get(`${process.env.VUE_APP_BASE_URL}/api/web/phase`).then(({ data }) => {
      console.log('phase:', data.data)
      dispatch('bigScreen/checkPhase', data.data, { root: true })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
