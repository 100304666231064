<template>
  <div v-show="isShow" class="screen-saver" @click.stop="onTouchClick">
    <splide
      v-if="isShow"
      :options="options"
      :slides="screenSaver"
      @splide:move="onSplideMove"
    >
      <splide-slide
        v-for="item in screenSaver"
        :key="item.pic"
        :style="{backgroundImage: `url(${item.pic})`}"
        class="screen-saver__touch-section"
      />
    </splide>
    <div class="screen-saver__triangle"></div>
    <div class="flex-column items-center screen-saver__touch-content">
      <div class="screen-saver__touch-icon"></div>
      <p class="font-bold screen-saver__touch-text">觸碰探索佈置靈感</p>
      <p class="font-bold screen-saver__touch-text--en">Touch to explore inspirations</p>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import lottie from 'lottie-web'
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import hand from '@/assets/lottie/hands.json'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'

export default {
  name: 'ScreenSaver',
  components: {
    Splide,
    SplideSlide
  },
  data () {
    return {
      options: {
        perPage: 1,
        type: 'fade',
        rewind: true,
        autoplay: true,
        interval: 4000,
        speed: 1500,
        width: '100vw',
        height: '100vh',
        pauseOnHover: false,
        pauseOnFocus: false,
        arrows: false,
        pagination: false,
        drag: false
      }
    }
  },
  computed: {
    ...mapState('screensaver', ['isShow']),
    ...mapState('api', ['screenSaver'])
  },
  mounted () {
    this.$nextTick(() => {
      lottie.loadAnimation({
        container: document.querySelector('.screen-saver__touch-icon'),
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: hand
      })
    })
  },
  methods: {
    ...mapMutations('screensaver', ['RESET_TIMER', 'UPDATE_SHOW']),
    ...mapActions('api', ['fetchBigScreenPhase']),
    ...mapActions('gtagEvents', ['screenSaverClickTrack']),
    onTouchClick () {
      this.screenSaverClickTrack()
      if (this.$route.name === 'Home') {
        this.RESET_TIMER()
        this.UPDATE_SHOW(false)
        this.fetchBigScreenPhase({ current: '00', current_id: '', lang: this.lang })
      } else {
        this.$router.push({ name: 'Home' })
        this.RESET_TIMER()
        this.UPDATE_SHOW(false)
      }
    },
    onSplideMove (splide, newIndex, oldIndex, destIndex) {
      this.fetchBigScreenPhase({ current: 'screensaver', current_id: destIndex, lang: this.lang })
    }
  }
}
</script>

<style lang="scss" scoped>
.screen-saver {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  overflow: hidden;
  background-color: $white;

  &__touch-section {
    @include bg-cover;
  }

  &__triangle {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 29.16667vw 53.256667vh 29.16667vw 0;
    border-color: transparent $white transparent transparent;
    transform: translateY(-20px);
  }

  &__touch-content {
    position: absolute;
    top: 35.0926vh;
    right: 2.604vw;
  }

  &__touch-icon {
    width: 5.921875vw;
    height: 9.8981vh;
    margin-bottom: 2vh;

    &::v-deep svg {
      width: 170% !important;
      height: 170% !important;
      transform: translateY(-2.083333vw) translateX(-2.083333vw) !important;
    }
  }

  &__touch-text {
    font-size: 2.24vw;
    line-height: normal;

    &--en {
      font-size: 1.5104vw;
      letter-spacing: 0.52;
      letter-spacing: -0.0453125vw;
    }
  }
}
</style>
