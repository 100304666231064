<template>
  <div class="breadcrumb" :class="{'breadcrumb__en': lang !== 'hk'}">
    <span
      v-for="(item, idx) in breadcrumbName"
      :key="idx"
      class="breadcrumb__layer"
      :class="{
        'font-bold breadcrumb__layer-active': idx === breadcrumbName.length - 1,
        'breadcrumb__layer-active-one--en': idx === breadcrumbName.length - 1 && lang !== 'hk' && ($route.name === 'Inspiration' || $route.name === 'Highlight'),
        'breadcrumb__layer-active--en': idx === breadcrumbName.length - 1 && lang !== 'hk' && $route.name !== 'Inspiration' && $route.name !== 'Highlight'
      }"
    >
      {{ item }}
    </span>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'BreadCrumb',
  computed: {
    ...mapState('breadcrumb', ['breadcrumb']),
    ...mapState('lang', ['lang']),
    breadcrumbName () {
      const nameArr = []
      const b = this.$route.meta.breadcrumb
      const target = this.lang === 'hk' ? this.breadcrumb[b] : this.breadcrumb[`${b}_en`]

      if (b === 'category') {
        const layer1 = this.lang === 'hk' ? this.breadcrumb.inspiration : this.breadcrumb.inspiration_en
        const layer2 = target.find(item => item.id === parseInt(this.$route.params.id)).name

        nameArr.push(layer1)
        nameArr.push(layer2)
      } else if (b === 'product' && this.$route.name === 'CategoryProduct') {
        let layer1 = this.lang === 'hk' ? this.breadcrumb.inspiration : this.breadcrumb.inspiration_en
        const layer2 = this.lang === 'hk' ? this.breadcrumb.category.find(item => item.id === parseInt(this.$route.params.id)).name : this.breadcrumb.category_en.find(item => item.id === parseInt(this.$route.params.id)).name
        const layer3 = target.find(item => item.id === parseInt(this.$route.params.productId)).title

        if (layer3.length > 40) layer1 = '...'

        nameArr.push(layer1)
        nameArr.push(layer2)
        nameArr.push(layer3)
      } else if (b === 'highlight_product' && this.$route.name === 'HighlightProduct') {
        const layer1 = this.lang === 'hk' ? this.breadcrumb.highlight : this.breadcrumb.highlight_en
        const layer2 = target

        nameArr.push(layer1)
        nameArr.push(layer2)
      } else {
        nameArr.push(target)
      }

      return nameArr
    }
  }
}
</script>

<style lang="scss" scoped>
.breadcrumb {
  font-size: 2.2396vw;
  line-height: normal;
  letter-spacing: normal;
  display: flex;
  align-items: center;

  &__en {
    font-size: 1.40625vw;
  }

  &__arrow {
    margin: 0 0.83333vw;
  }

  &__layer {
    &::after {
      content: '⟩';
      margin-right: 0.83333vw;
    }

    &:last-child {
      &::after {
        content: '';
        margin: 0;
      }
    }
  }

  &__layer-active {
    font-size: 2.604vw;

    &--en {
      font-size: 1.5625vw;
    }
  }

  &__layer-active-one {
    &--en {
      font-size: 2.1875vw;
    }
  }
}
</style>
