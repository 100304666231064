const state = {
  data: {},
  data_en: {}
}

const mutations = {
  UPDATE_DATA (state, payload) {
    state.data = payload
  },
  UPDATE_EN_DATA (state, payload) {
    state.data_en = payload
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
