<template>
  <div v-if="isTimerShow" class="d-flex justify-center items-center screen-saver-timer">
    <div class="flex-column items-center justify-center screen-saver-timer__timer">
      <img src="@/assets/img/clock.png">
      <p
        v-if="lang === 'hk'"
        class="font-bold screen-saver-timer__text"
      >
        {{ count }}秒後將自動回到首頁
      </p>
      <p
        v-else
        class="font-bold screen-saver-timer__text screen-saver-timer__text--en"
      >
        Returning to homepage after {{ count }} seconds
      </p>
      <button
        class="font-bold screen-saver-timer__btn"
        :class="{'screen-saver-timer__btn--en': lang !== 'hk'}"
      >
        {{ buttonText }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
  name: 'ScreenSaverTimer',
  data () {
    return {
      timer: null
    }
  },
  computed: {
    ...mapState('screensaver', ['isTimerShow', 'count']),
    ...mapState('lang', ['lang']),
    text () {
      return this.lang === 'hk' ? '秒後將自動回到首頁' : 'Returning to homepage after  seconds'
    },
    buttonText () {
      return this.lang === 'hk' ? '繼續瀏覽' : 'Continue browsing'
    }
  },
  watch: {
    isTimerShow: {
      handler () {
        if (this.isTimerShow) {
          this.timer = setInterval(() => {
            this.UPDATE_COUNT()
          }, 1000)
        } else {
          clearInterval(this.timer)
          this.RESET_COUNT()
        }
      }
    },
    count: {
      handler () {
        if (this.count === 0) {
          this.fetchBigScreenPhase({ current: 'screensaver', current_id: '', lang: this.lang })
          this.UPDATE_SHOW(true)
          clearInterval(this.timer)
          this.RESET_COUNT()
          this.UPDATE_TIMER_SHOW(false)
          this.REMOVE_PRODUCT_ALL()
        }
      }
    }
  },
  methods: {
    ...mapMutations('screensaver', ['UPDATE_COUNT', 'RESET_COUNT', 'UPDATE_SHOW', 'UPDATE_TIMER_SHOW']),
    ...mapMutations('cart', ['REMOVE_PRODUCT_ALL']),
    ...mapActions('api', ['fetchBigScreenPhase'])
  }
}
</script>

<style lang="scss" scoped>
.screen-saver-timer {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;

  &__timer {
    width: 21.354vw;
    height: 21.354vw;
    background-color: rgba($color: $black, $alpha: 0.7);
    border-radius: 50%;

    img {
      width: 3.61vw;
      margin-bottom: 12px;
    }
  }

  &__text {
    font-size: 1.6146vw;
    letter-spacing: -0.0323vw;
    text-align: center;
    color: $white;
    line-height: normal;
    max-width: 186px;

    &--en {
      max-width: none;
    }
  }

  &__btn {
    width: 12.59375vw;
    height: 6.2037vh;
    background-color: $white;
    border-radius: 1.6146vw;
    border: 1px solid $deep-gray;
    font-size: 1.6146vw;
    letter-spacing: -0.0323vw;
    color: #4d4d4d;
    margin-top: 1.4815vh;

    &--en {
      width: 15.09375vw;
      font-size: 1.40625vw;
      letter-spacing: -0.0421875vw;
    }
  }
}
</style>
