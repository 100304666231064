const state = {
  isShow: false,
  isTimerShow: true,
  timer: null,
  count: 10
}

const mutations = {
  RESET_TIMER (state) {
    console.log('reset')
    state.isTimerShow = false
    if (state.timer) clearTimeout(state.timer)

    state.timer = setTimeout(() => {
      state.isTimerShow = true
    }, 40000)
  },
  UPDATE_COUNT (state) {
    state.count -= 1
  },
  RESET_COUNT (state) {
    state.count = 10
  },
  UPDATE_SHOW (state, payload) {
    state.isShow = payload
  },
  UPDATE_TIMER_SHOW (state, payload) {
    state.isTimerShow = payload
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
