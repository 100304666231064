// 此檔案用來管理 navbar 的 state
const state = {
  cart_hk: '您的購物籃',
  cart_en: 'Your shopping cart'
}

const mutations = {}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
