// 用來管理切換語系
const state = {
  lang: 'en'
}

const mutations = {
  TOGGLE_LANG (state, lang) {
    state.lang = lang
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
