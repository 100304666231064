import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/inspiration',
    name: 'Inspiration',
    component: () => import('../views/Inspiration.vue'),
    meta: {
      breadcrumb: 'inspiration'
    }
  },
  {
    path: '/inspiration-category/:id',
    component: () => import('../views/Category.vue'),
    children: [
      {
        path: '',
        name: 'Category',
        props: true,
        component: () => import('../components/category/CategorySpace.vue'),
        meta: {
          breadcrumb: 'category'
        }
      },
      {
        path: 'product/:productId',
        name: 'CategoryProduct',
        props: true,
        component: () => import('../views/Product.vue'),
        meta: {
          breadcrumb: 'product'
        }
      }
    ]
  },
  {
    path: '/highlight',
    component: () => import('../views/Highlight.vue'),
    children: [
      {
        path: '',
        name: 'Highlight',
        component: () => import('../components/highlight/HighlightHome.vue'),
        meta: {
          breadcrumb: 'highlight'
        }
      },
      {
        path: 'product/:productId',
        name: 'HighlightProduct',
        props: true,
        component: () => import('../views/Product.vue'),
        meta: {
          breadcrumb: 'highlight_product'
        }
      }
    ]
  },
  {
    path: '/cart',
    component: () => import('../views/Cart.vue'),
    name: 'Cart'
  },
  {
    path: '/top-carousel',
    component: () => import('../views/TopCarousel.vue'),
    name: 'TopCarousel'
  },
  {
    path: '/bottom-carousel',
    component: () => import('../views/BottomCarousel.vue'),
    name: 'BottomCarousel'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.NODE_ENV === 'production'
    ? '/inspiration_wall/'
    : '/',
  routes
})

export default router
