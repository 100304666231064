import Vue from 'vue'
import Vuex from 'vuex'
import global from './global'
import api from './api'
import lang from './lang'
import navbar from './navbar'
import home from './home'
import inspiration from './inspiration'
import breadcrumb from './breadcrumb'
import cart from './cart'
import screensaver from './screensaver'
import product from './product'
import highlight from './highlight'
import bigScreen from './bigScreen'
import gtagEvents from './gtagEvents'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    global,
    api,
    lang,
    navbar,
    home,
    inspiration,
    breadcrumb,
    cart,
    screensaver,
    product,
    highlight,
    bigScreen,
    gtagEvents
  }
})
